export const ControllerBundleCustomization = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundleCustomization.js' /* webpackChunkName: "components/controller-bundle-customization" */).then(c => wrapFunctional(c.default || c))
export const ControllerBundles = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerBundles.js' /* webpackChunkName: "components/controller-bundles" */).then(c => wrapFunctional(c.default || c))
export const ControllerCalendar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js' /* webpackChunkName: "components/controller-calendar" */).then(c => wrapFunctional(c.default || c))
export const ControllerCart = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCart.js' /* webpackChunkName: "components/controller-cart" */).then(c => wrapFunctional(c.default || c))
export const ControllerCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCategory.js' /* webpackChunkName: "components/controller-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerCommentList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCommentList.js' /* webpackChunkName: "components/controller-comment-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerConfirmEmail = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerConfirmEmail.js' /* webpackChunkName: "components/controller-confirm-email" */).then(c => wrapFunctional(c.default || c))
export const ControllerContactForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js' /* webpackChunkName: "components/controller-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerCookie = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCookie.js' /* webpackChunkName: "components/controller-cookie" */).then(c => wrapFunctional(c.default || c))
export const ControllerCrossSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCrossSell.js' /* webpackChunkName: "components/controller-cross-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerCustomForm = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js' /* webpackChunkName: "components/controller-custom-form" */).then(c => wrapFunctional(c.default || c))
export const ControllerError = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js' /* webpackChunkName: "components/controller-error" */).then(c => wrapFunctional(c.default || c))
export const ControllerEvent = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js' /* webpackChunkName: "components/controller-event" */).then(c => wrapFunctional(c.default || c))
export const ControllerFeaturedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFeaturedProducts.js' /* webpackChunkName: "components/controller-featured-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerFooter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js' /* webpackChunkName: "components/controller-footer" */).then(c => wrapFunctional(c.default || c))
export const ControllerHeader = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js' /* webpackChunkName: "components/controller-header" */).then(c => wrapFunctional(c.default || c))
export const ControllerLayeredNavigation = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerLayeredNavigation.js' /* webpackChunkName: "components/controller-layered-navigation" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationBar = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js' /* webpackChunkName: "components/controller-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const ControllerNavigationItem = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationItem.js' /* webpackChunkName: "components/controller-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const ControllerNewsletter = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerNewsletter.js' /* webpackChunkName: "components/controller-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ControllerPost = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js' /* webpackChunkName: "components/controller-post" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategory.js' /* webpackChunkName: "components/controller-post-category" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js' /* webpackChunkName: "components/controller-post-category-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js' /* webpackChunkName: "components/controller-post-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerPostReview = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostReview.js' /* webpackChunkName: "components/controller-post-review" */).then(c => wrapFunctional(c.default || c))
export const ControllerProduct = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js' /* webpackChunkName: "components/controller-product" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCategories = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCategories.js' /* webpackChunkName: "components/controller-product-categories" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductConfiguration = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductConfiguration.js' /* webpackChunkName: "components/controller-product-configuration" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductCustomOptions = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductCustomOptions.js' /* webpackChunkName: "components/controller-product-custom-options" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductList.js' /* webpackChunkName: "components/controller-product-list" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductListContainer = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductListContainer.js' /* webpackChunkName: "components/controller-product-list-container" */).then(c => wrapFunctional(c.default || c))
export const ControllerProductsListFiltered = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductsListFiltered.js' /* webpackChunkName: "components/controller-products-list-filtered" */).then(c => wrapFunctional(c.default || c))
export const ControllerPublicUpload = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerPublicUpload.js' /* webpackChunkName: "components/controller-public-upload" */).then(c => wrapFunctional(c.default || c))
export const ControllerRecentlyViewedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRecentlyViewedProducts.js' /* webpackChunkName: "components/controller-recently-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerRelatedProducts = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerRelatedProducts.js' /* webpackChunkName: "components/controller-related-products" */).then(c => wrapFunctional(c.default || c))
export const ControllerSearch = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js' /* webpackChunkName: "components/controller-search" */).then(c => wrapFunctional(c.default || c))
export const ControllerUpSell = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUpSell.js' /* webpackChunkName: "components/controller-up-sell" */).then(c => wrapFunctional(c.default || c))
export const ControllerUserComment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/ControllerUserComment.js' /* webpackChunkName: "components/controller-user-comment" */).then(c => wrapFunctional(c.default || c))
export const ControllerAddress = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerAddress.js' /* webpackChunkName: "components/controller-address" */).then(c => wrapFunctional(c.default || c))
export const ControllerPayment = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerPayment.js' /* webpackChunkName: "components/controller-payment" */).then(c => wrapFunctional(c.default || c))
export const ControllerShipping = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/checkout/ControllerShipping.js' /* webpackChunkName: "components/controller-shipping" */).then(c => wrapFunctional(c.default || c))
export const ControllerChangePassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerChangePassword.js' /* webpackChunkName: "components/controller-change-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerForgotPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerForgotPassword.js' /* webpackChunkName: "components/controller-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js' /* webpackChunkName: "components/controller-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerOrderHistory = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerOrderHistory.js' /* webpackChunkName: "components/controller-order-history" */).then(c => wrapFunctional(c.default || c))
export const ControllerRegister = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerRegister.js' /* webpackChunkName: "components/controller-register" */).then(c => wrapFunctional(c.default || c))
export const ControllerResetPassword = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerResetPassword.js' /* webpackChunkName: "components/controller-reset-password" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLogin = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLogin.js' /* webpackChunkName: "components/controller-social-login" */).then(c => wrapFunctional(c.default || c))
export const ControllerSocialLoginButtons = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerSocialLoginButtons.js' /* webpackChunkName: "components/controller-social-login-buttons" */).then(c => wrapFunctional(c.default || c))
export const ControllerUser = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerUser.js' /* webpackChunkName: "components/controller-user" */).then(c => wrapFunctional(c.default || c))
export const ControllerWishlist = () => import('../../node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerWishlist.js' /* webpackChunkName: "components/controller-wishlist" */).then(c => wrapFunctional(c.default || c))
export const EditorIcon = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorIcon.js' /* webpackChunkName: "components/editor-icon" */).then(c => wrapFunctional(c.default || c))
export const EditorImage = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js' /* webpackChunkName: "components/editor-image" */).then(c => wrapFunctional(c.default || c))
export const EditorImageMarkers = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorImageMarkers.js' /* webpackChunkName: "components/editor-image-markers" */).then(c => wrapFunctional(c.default || c))
export const EditorInstagramPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorInstagramPostList.js' /* webpackChunkName: "components/editor-instagram-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorLink = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js' /* webpackChunkName: "components/editor-link" */).then(c => wrapFunctional(c.default || c))
export const EditorList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorList.js' /* webpackChunkName: "components/editor-list" */).then(c => wrapFunctional(c.default || c))
export const EditorListItem = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js' /* webpackChunkName: "components/editor-list-item" */).then(c => wrapFunctional(c.default || c))
export const EditorMap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js' /* webpackChunkName: "components/editor-map" */).then(c => wrapFunctional(c.default || c))
export const EditorPostList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorPostList.js' /* webpackChunkName: "components/editor-post-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductCategoryList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductCategoryList.js' /* webpackChunkName: "components/editor-product-category-list" */).then(c => wrapFunctional(c.default || c))
export const EditorProductList = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorProductList.js' /* webpackChunkName: "components/editor-product-list" */).then(c => wrapFunctional(c.default || c))
export const EditorTipTap = () => import('../../node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js' /* webpackChunkName: "components/editor-tip-tap" */).then(c => wrapFunctional(c.default || c))
export const Compare = () => import('../../components/ui/Compare.vue' /* webpackChunkName: "components/compare" */).then(c => wrapFunctional(c.default || c))
export const LangSwitcher = () => import('../../components/ui/LangSwitcher.vue' /* webpackChunkName: "components/lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const RadioDropdownSelectCustom = () => import('../../components/ui/RadioDropdownSelectCustom.vue' /* webpackChunkName: "components/radio-dropdown-select-custom" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/ui/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const ActivityRadioDropdown = () => import('../../components/ui/activityRadioDropdown.vue' /* webpackChunkName: "components/activity-radio-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AnimationCube = () => import('../../components/ui/animation-cube.vue' /* webpackChunkName: "components/animation-cube" */).then(c => wrapFunctional(c.default || c))
export const Articles = () => import('../../components/ui/articles.vue' /* webpackChunkName: "components/articles" */).then(c => wrapFunctional(c.default || c))
export const Blog = () => import('../../components/ui/blog.vue' /* webpackChunkName: "components/blog" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/ui/btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const ContactButton = () => import('../../components/ui/contact-button.vue' /* webpackChunkName: "components/contact-button" */).then(c => wrapFunctional(c.default || c))
export const Cube = () => import('../../components/ui/cube.vue' /* webpackChunkName: "components/cube" */).then(c => wrapFunctional(c.default || c))
export const Downloads = () => import('../../components/ui/downloads.vue' /* webpackChunkName: "components/downloads" */).then(c => wrapFunctional(c.default || c))
export const FaqQuestion = () => import('../../components/ui/faq-question.vue' /* webpackChunkName: "components/faq-question" */).then(c => wrapFunctional(c.default || c))
export const Helpdesk = () => import('../../components/ui/helpdesk.vue' /* webpackChunkName: "components/helpdesk" */).then(c => wrapFunctional(c.default || c))
export const IconAppleStore = () => import('../../components/ui/icon-apple-store.vue' /* webpackChunkName: "components/icon-apple-store" */).then(c => wrapFunctional(c.default || c))
export const IconGooglePlay = () => import('../../components/ui/icon-google-play.vue' /* webpackChunkName: "components/icon-google-play" */).then(c => wrapFunctional(c.default || c))
export const InView = () => import('../../components/ui/inView.vue' /* webpackChunkName: "components/in-view" */).then(c => wrapFunctional(c.default || c))
export const MultiselectDropdown = () => import('../../components/ui/multiselectDropdown.vue' /* webpackChunkName: "components/multiselect-dropdown" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireDropdownMultiple = () => import('../../components/ui/questionnaireDropdownMultiple.vue' /* webpackChunkName: "components/questionnaire-dropdown-multiple" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireDropdownSingle = () => import('../../components/ui/questionnaireDropdownSingle.vue' /* webpackChunkName: "components/questionnaire-dropdown-single" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireInputText = () => import('../../components/ui/questionnaireInputText.vue' /* webpackChunkName: "components/questionnaire-input-text" */).then(c => wrapFunctional(c.default || c))
export const QuestionnaireRadio = () => import('../../components/ui/questionnaireRadio.vue' /* webpackChunkName: "components/questionnaire-radio" */).then(c => wrapFunctional(c.default || c))
export const RadioDropdownSelect = () => import('../../components/ui/radioDropdownSelect.vue' /* webpackChunkName: "components/radio-dropdown-select" */).then(c => wrapFunctional(c.default || c))
export const SelectOptions = () => import('../../components/ui/selectOptions.vue' /* webpackChunkName: "components/select-options" */).then(c => wrapFunctional(c.default || c))
export const StepAssociates = () => import('../../components/ui/stepAssociates.vue' /* webpackChunkName: "components/step-associates" */).then(c => wrapFunctional(c.default || c))
export const StepPackages = () => import('../../components/ui/stepPackages.vue' /* webpackChunkName: "components/step-packages" */).then(c => wrapFunctional(c.default || c))
export const SupportHeroSearch = () => import('../../components/ui/support-hero-search.vue' /* webpackChunkName: "components/support-hero-search" */).then(c => wrapFunctional(c.default || c))
export const SwiperComponent = () => import('../../components/ui/swiperComponent.vue' /* webpackChunkName: "components/swiper-component" */).then(c => wrapFunctional(c.default || c))
export const Tutorials = () => import('../../components/ui/tutorials.vue' /* webpackChunkName: "components/tutorials" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/ui/uiCheckbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiDownloads = () => import('../../components/ui/uiDownloads.vue' /* webpackChunkName: "components/ui-downloads" */).then(c => wrapFunctional(c.default || c))
export const UiInput = () => import('../../components/ui/uiInput.vue' /* webpackChunkName: "components/ui-input" */).then(c => wrapFunctional(c.default || c))
export const UiPost = () => import('../../components/ui/uiPost.vue' /* webpackChunkName: "components/ui-post" */).then(c => wrapFunctional(c.default || c))
export const UiRadio = () => import('../../components/ui/uiRadio.vue' /* webpackChunkName: "components/ui-radio" */).then(c => wrapFunctional(c.default || c))
export const UiSelect = () => import('../../components/ui/uiSelect.vue' /* webpackChunkName: "components/ui-select" */).then(c => wrapFunctional(c.default || c))
export const UiTextarea = () => import('../../components/ui/uiTextarea.vue' /* webpackChunkName: "components/ui-textarea" */).then(c => wrapFunctional(c.default || c))
export const UiTutorial = () => import('../../components/ui/uiTutorial.vue' /* webpackChunkName: "components/ui-tutorial" */).then(c => wrapFunctional(c.default || c))
export const VerticalTabs = () => import('../../components/ui/vertical-tabs.vue' /* webpackChunkName: "components/vertical-tabs" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../components/animations/transition-expand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
