
import { hr } from "vuejs-datepicker/dist/locale";

export default {
  props: {
    inputClasses: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    labelFor: {
      type: String,
      default: "",
    },
    withBtn: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showErrorMsg: {
      type: Object,
    },
    customForm: {
      type: Boolean,
      default: false,
    },
    questionnaireError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileName: "",
      hr,
      disabledDates: {
        to: null,
        days: ["6", "0"], // Disable Saturday's and Sunday's
      },
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.type === "date") {
          const dateToString = val ? val.toString() : "";
          this.$emit("input", dateToString);
        } else {
          this.$emit("input", val);
        }
      },
    },
  },
  mounted() {
    if (this.type === "date") {
      this.disabledDates.to = this.getYesterday();
    }
  },
  methods: {
    onFileChange(event) {
      if (this.type === "file") {
        const fileData = event.target.files[0];
        if (fileData && fileData.name) {
          this.fileName = fileData.name;
        }
      }
    },
    getDate() {
      const date = new Date();
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}.`;
    },
    getYesterday() {
      return new Date(Date.now() - 86400000);
    },
  },
};
